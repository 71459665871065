<template>
    <loader v-bind="{ loading }" text="Loading Areas">
        <columns>
            <column class="is-narrow">

            </column>
            <column>
                <form>
                    <text-input v-model="filters.search" classes="has-addons is-rounded"
                        placeholder="Search for areas...">
                        <template #right>
                            <div class="buttons has-addons is-rounded">
                                <submit-button @submit="runSearch" class="is-rounded">
                                    Search
                                </submit-button>
                                <action-button v-if="$route.query.search" @click="clearFilters">
                                    <icon icon="times" />
                                </action-button>
                                <router-link v-if="!$root.isPassiveUser()" class="button is-rounded" :to="{
                                    name: 'create-location-area',
                                    params: {
                                        location: location.uuid
                                    }
                                }">
                                    <icon icon="plus" />
                                    <span>Area</span>
                                </router-link>
                            </div>
                        </template>
                    </text-input>
                </form>
            </column>
        </columns>
        <columns>
            <column>
                <div class="box" v-for="area in areas.data" :key="area.id">
                    <columns>
                        <column>
                            <router-link :to="{
                                name: 'edit-location-area',
                                params: {
                                    location: location.uuid,
                                    area: area.uuid
                                }
                            }">
                                {{ area.name }}
                            </router-link>
                        </column>
                    </columns>
                </div>
                <pager v-if="areas.data.length" :pageable="areas" context="area" jump-controls @nav="goToPage" />

                <no-items-to-display :item-count="areas.data.length"
                    heading="There are no areas available for display." />
            </column>
        </columns>
    </loader>
</template>
<script>
import { get } from '@/api/request'
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        loading: true,
        filters: {
            search: ''
        },
        areas: {
            data: []
        }
    }),

    created() {
        if (this.$route.query) {
            this.filters.search = this.$route.query.search
        }
        this.loadAreas()
    },

    methods: {
        loadAreas() {
            get('v1' + this.$route.fullPath, ({ data }) => {
                this.areas = data
                this.loading = false
            }, () => {
                this.loading = false
            })
        },
        goToPage(path) {
            this.$router.push(path)
        },
        runSearch() {
            this.$router.push({
                name: 'location-areas',
                params: {
                    location: this.$route.params.location
                },
                query: this.filters
            });
        },
        clearFilters() {
            this.filters.search = ''
            this.$router.push({
                params: {
                    location: this.$route.params.location
                },
                name: 'location-areas',
            });
        },
    },

    computed: {
        ...mapGetters('location', [
            'location'
        ])
    },

    watch: {
        '$route': 'loadAreas'
    }

}
</script>